import { useEffect, useState } from "react";
import { useFormContext, Controller, useFieldArray } from "react-hook-form";
import { Row, Col, FormGroup, Alert, Button } from "reactstrap";
import FormInput from "components/Forms/FormInput";
import Select from "react-select";
import { reactSelectStyles } from "consts";

export default function UserInfoForm() {
	const {
		register,
		control,
		formState: { errors },
	} = useFormContext();

	const { fields, append, remove } = useFieldArray({
		control,
		name: "users",
	});

	function addClick() {
		append({
			first_name: "",
			last_name: "",
			email: "",
			job_title: "Estimator",
		});
	}

	useEffect(() => {
		if (fields.length === 0) {
			append({
				first_name: "",
				last_name: "",
				email: "",
				job_title: "Estimator",
			});
		}
	}, [append, fields.length]);

	return (
		<>
			<header>
				<h2 className='text-uppercase'>User Information</h2>
			</header>
			<hr className='line-info' />
			<br></br>
			{fields.map((field, index) => (
				<div key={field.id}>
					{index > 0 && <hr className='line-info' />}
					<h4>User number {index + 1}</h4>
					<Row>
						<Col className='align-self-center' md='3'>
							<label className='labels' htmlFor={`users[${index}].first_name`}>
								First Name
							</label>
						</Col>
						<Col className='align-self-center' md='9'>
							<FormGroup>
								<FormInput
									type='text'
									id={`users[${index}].first_name`}
									name={`users[${index}].first_name`}
									placeholder='Charlie'
									register={register}
									validations={{ required: true }}
								/>
								{errors.users?.[index]?.first_name && (
									<Alert color='danger'>This field is required</Alert>
								)}
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col className='align-self-center' md='3'>
							<label className='labels' htmlFor={`users[${index}].last_name`}>
								Last Name
							</label>
						</Col>
						<Col className='align-self-center' md='9'>
							<FormGroup>
								<FormInput
									type='text'
									id={`users[${index}].last_name`}
									name={`users[${index}].last_name`}
									placeholder='Bailey'
									register={register}
									validations={{ required: true }}
								/>
								{errors.users?.[index]?.last_name && (
									<Alert color='danger'>This field is required</Alert>
								)}
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col className='align-self-center' md='3'>
							<label className='labels' htmlFor={`users[${index}].email`}>
								Email
							</label>
						</Col>
						<Col className='align-self-center' md='9'>
							<FormGroup>
								<FormInput
									type='text'
									id={`users[${index}].email`}
									name={`users[${index}].email`}
									placeholder='charlie.bailey@example.com'
									register={register}
									validations={{ required: true }}
								/>
								{errors.users?.[index]?.email && (
									<Alert color='danger'>This field is required</Alert>
								)}
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col className='align-self-center' md='3'>
							<label className='labels' htmlFor={`users[${index}].job_title`}>
								Job Title
							</label>
						</Col>
						<Col className='align-self-center' md='9'>
							<FormGroup>
								<Controller
									control={control}
									name={`users[${index}].job_title`}
									defaultValue='Estimator'
									render={({ field }) => (
										<Select
											{...field}
											styles={reactSelectStyles}
											placeholder='Job Title'
											options={[
												{ value: "Estimator", label: "Estimator" },
												{ value: "Shop Manager", label: "Shop Manager" },
												{
													value: "Shop Regional Manager",
													label: "Shop Regional Manager",
												},
												{ value: "Company Owner", label: "Company Owner" },
												{ value: "Technician", label: "Technician" },
											]}
											value={{
												value: field.value,
												label: field.value,
											}}
											onChange={(selectedOption) =>
												field.onChange(selectedOption.value)
											}
										/>
									)}
								/>
								{errors.users?.[index]?.job_title && (
									<Alert color='danger'>This field is required</Alert>
								)}
							</FormGroup>
						</Col>
					</Row>
					<Button color='warning' type='button' onClick={() => remove(index)}>
						Remove User
					</Button>
					<br></br>
				</div>
			))}
			<Button color='info' type='button' onClick={addClick}>
				Add user
			</Button>
		</>
	);
}

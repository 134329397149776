import { forwardRef, useImperativeHandle, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Alert, FormGroup, Row, Col } from "reactstrap";
import FormInput from "components/Forms/FormInput";
import axios from "utils/Axios";
import { toast } from "react-toastify";

export default forwardRef(function ActivationCodeForm(props, ref) {
	const {
		register,
		setValue,
		getValues,
		formState: { errors },
	} = useFormContext();
	const [validationCodeError, setValidationCodeError] = useState("");
	const [validationCodeValid, setValidationCodeValid] = useState(false);

	useImperativeHandle(ref, () => ({
		async validateCode() {
			return await checkValidationCode();
		},
	}));

	function onTypeActivationCode(e) {
		setValue("code", e.target.value);
		if (validationCodeError) setValidationCodeError("");
	}

	async function checkValidationCode() {
		const validationCode = getValues("code");
		try {
			await axios.post("/activation/register_company_shop_users", {
				code: validationCode,
			});

			setValidationCodeValid(true);
			return true;
		} catch (error) {
			setValidationCodeValid(false);

			if (error.response?.status === 500) {
				setValidationCodeError("Error: Activation code length is incorrect");
				toast.error("Error: Activation code length is incorrect");
			} else if (error.response?.status === 403) {
				setValidationCodeError(
					"Invalid Activation code entered, please try again"
				);
				toast.error("Invalid Activation code entered, please try again");
			} else {
				setValidationCodeError(
					"Server error when validating code. Please try again later."
				);
				toast.error(
					"Server error when validating code. Please try again later"
				);
			}

			return false;
		}
	}

	return (
		<>
			<header>
				<h2 className='text-uppercase'>Activation Code</h2>
			</header>
			<hr className='line-info' />
			<br />
			<Row>
				<Col className='align-self-center' md='3'>
					<label className='labels' htmlFor='code'>
						Code
					</label>
				</Col>
				<Col className='align-self-center' md='9'>
					<FormGroup>
						<FormInput
							type='text'
							id='code'
							name='code'
							placeholder='Activation Code'
							onChange={onTypeActivationCode}
							register={register}
							validations={{ required: true }}
						/>
						{validationCodeValid && (
							<Alert color='success'>Activation code is valid!</Alert>
						)}
						{errors.code && (
							<Alert color='danger'>This field is required</Alert>
						)}
						{validationCodeError && (
							<Alert color='danger'>{validationCodeError}</Alert>
						)}
					</FormGroup>
				</Col>
			</Row>
		</>
	);
});

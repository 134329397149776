import { useState, useRef, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";

import { Alert, Button, Form, Container, Row, Col, Spinner } from "reactstrap";

import VsstaHomeNavbar from "components/Navbars/VsstaHomeNavbar.js";
import { toast } from "react-toastify";

import axios from "utils/Axios";
import { useMultistepForm } from "hooks/useMultistepForm";
import ActivationCodeForm from "components/Forms/SignUpForms/ActivationCodeForm";
import BillingInfoForm from "components/Forms/SignUpForms/BillingInfoForm";
import CompanyShopInfoForm from "components/Forms/SignUpForms/CompanyShopInfoForm";
import ShopAddressForm from "components/Forms/SignUpForms/ShopAddressForm";
import UserInfoForm from "components/Forms/SignUpForms/UserInfoForm";
import ShopSpecificsForm from "components/Forms/SignUpForms/ShopSpecificsForm";

export default function SignUpTicket() {
	const methods = useForm();
	const { handleSubmit, trigger, reset } = methods;
	const [formSuccess, setFormSuccess] = useState(false);
	const [loadingState, setLoadingState] = useState(false);

	const wrapper = useRef(null);
	const validateCodeHandle = useRef(null);

	const { steps, currentStepIndex, goTo, next, back } = useMultistepForm([
		<ActivationCodeForm key='activation' ref={validateCodeHandle} />,
		<BillingInfoForm key='billing' />,
		<CompanyShopInfoForm key='company' />,
		<ShopAddressForm key='address' />,
		<ShopSpecificsForm key='specifics' />,
		<UserInfoForm key='user' />,
	]);

	useEffect(() => {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
		wrapper.current.scrollTop = 0;
		document.body.classList.add("account-settings");
		return function cleanup() {
			document.body.classList.remove("account-settings");
		};
	}, []);

	async function handleNext() {
		const formValid = await trigger();
		if (!formValid) return;

		// Activate code form: send code to the backend to check if it is valid
		if (currentStepIndex === 0) {
			const codeValid = await validateCodeHandle?.current?.validateCode();
			if (!codeValid) return;
		}

		next();
	}

	function onSubmit(data) {
		var backendData = data;
		setLoadingState(true);
		axios
			.post("/activation/register_company_shop_users", backendData)
			.then((res) => {
				toast.success("Sign up successful!");
				reset(); // clears the form
				goTo(0); // goes back to the first step
				setFormSuccess(true);
				window.scrollTo(0, 0);
				setLoadingState(false);
			})
			.catch((error) => {
				const errorsList = error.response.data.errors.map((error) => {
					return <li><p>{error}</p></li>;
				});
				toast.error(<ol>{errorsList}</ol>);
				setFormSuccess(false);
				setLoadingState(false);
			});
	}

	function renderNextButton() {
		if (currentStepIndex < steps.length - 1) {
			return (
				<Button color='primary' onClick={handleNext}>
					Next
				</Button>
			);
		}
	}

	function renderPrevButton() {
		if (currentStepIndex > 0) {
			return (
				<Button color='info' onClick={back}>
					Prev
				</Button>
			);
		}
	}

	function renderSubmitButton() {
		const spinner = <Spinner size='sm' color='white' />;

		if (currentStepIndex === steps.length - 1) {
			return (
				<Button color='success' type='submit' disabled={loadingState}>
					{loadingState ? spinner : "Submit"}
				</Button>
			);
		}
	}

	return (
		<>
			<VsstaHomeNavbar />
			<div className='wrapper' ref={wrapper}>
				<div style={{ margin: "150px" }}></div>
				<div className='section'>
					<Container>
						<div>
							{formSuccess ? (
								<Alert color='success'>
									Thank You For Signing Up With Vssta! You Will Recieve an Email
									Shortly Containing Log In Information.{" "}
								</Alert>
							) : null}
						</div>
						<FormProvider {...methods}>
							<Form onSubmit={handleSubmit(onSubmit)} id='form'>
								<Row style={{ paddingLeft: "100px", paddingRight: "100px" }}>
									<Col className='ml-auto' md='12'>
										<div className='section'>
											<p>
												Step: {currentStepIndex + 1} / {steps.length}
											</p>
											{steps[currentStepIndex]}
											<br />
											<div className='d-flex justify-content-end'>
												{renderPrevButton()}
												{renderNextButton()}
												{renderSubmitButton()}
											</div>
										</div>
									</Col>
								</Row>
							</Form>
						</FormProvider>
						{/* <pre>{JSON.stringify(methods.watch(), null, 2)}</pre> */}
					</Container>
				</div>
			</div>
			<div style={{ margin: "250px" }}></div>
		</>
	);
}
